import React, { useState } from "react";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import DeleteFolder from "../../assets/deleteFolder.png";
import Successful from "./Successful";

const RemoveFolder = ({ onClose }) => {
  const [isRemoveFolder, setIsRemoveFolder] = useState(true);
  const [isSuccessfulCompleted, setIsSuccessfulCompleted] = useState(false);

  const handleRemoveFolderSuccessful = () => {
    setIsRemoveFolder(false);
    setIsSuccessfulCompleted(true);
  };

  return (
    <>
      {/* Remove Folder Dialog */}
      <Dialog
        open={isRemoveFolder}
        as="div"
        className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center"
        onClose={onClose}
      >
        <div className="fixed inset-0 z-50 w-screen bg-black bg-opacity-30 overflow-y-auto">
          <div className="flex flex-col min-h-full items-center justify-center p-4">
            <DialogPanel className="w-full flex justify-center items-center flex-col max-w-[300px] h-[300px] rounded-xl bg-white p-6 shadow-lg">
              <img src={DeleteFolder} alt="delete files" />
              <DialogTitle
                as="p"
                className="mt-2 font-Nunito font-[800] text-black"
              >
                Continue to delete ‘Item Type’
              </DialogTitle>
              <p className="text-[14px]">Action cannot be undone</p>
              <div className="mt-10 flex gap-4">
                <Button
                  className="text-center w-[79px] rounded-md border border-[#5608D4] py-1.5 px-3 text-sm font-semibold text-[#5608D4]"
                  onClick={onClose}
                >
                  No
                </Button>
                <Button
                  className="rounded-md text-center bg-gradient-to-r from-purple-custom w-[79px] to-blue-custom py-1.5 px-3 text-sm font-semibold text-white"
                  onClick={handleRemoveFolderSuccessful}
                >
                  Yes
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      {/* Successful Dialog */}
      <Successful
        isOpen={isSuccessfulCompleted}
        onClose={() => setIsSuccessfulCompleted(false)}
      />
    </>
  );
};

export default RemoveFolder;
