import React, {useState} from 'react'
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import ActionCompleted from '../cards/ActionCompleted';
import Users from "../../assets/usersDev.png"

const AllCollaborator = ({ onClose }) => {
  const [isCollboratorModalOpen, setIsCollboratorModalOpen] = useState(true);
  const [isActionCompletedOpen, setIsActionCompletedOpen] = useState(false);

  const handleCollaboratorSuccessful = () => {
    setIsCollboratorModalOpen(false);
    setIsActionCompletedOpen(true);
  };

  const handleActionCompletedClose = () => {
    setIsActionCompletedOpen(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={isCollboratorModalOpen}
        as="div"
        className="relative z-50 focus:outline-none"
        onClose={onClose}
      >
        <div className="fixed inset-0 z-50 w-screen bg-black bg-opacity-30 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-[600px] h-[438px] rounded-xl bg-white p-6 shadow-lg"
            >
              <p
                className="float-right text-3xl cursor-pointer "
                onClick={onClose}
              >
                &times;
              </p>

              <DialogTitle
                as="h3"
                className="text-[20px] font-[700] text-[#1D2939] font-Satoshi mt-6"
              >
                All Collaborators
              </DialogTitle>
              <p className="mt-2 text-[14px] text-[#475467]">
                Click on “cancel icon” to remove a collaborator
              </p>
              <div className="grid grid-cols-3 items-center place-content-center gap-4 mt-10">
                {[
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                  ,
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                  {
                    img: Users,
                    name: "Full Name",
                    email: "emailadd@gmail.com",
                  },
                ].map((collaborator) => (
                  <div className="border border-[#AA77FC] rounded-md bg-[#FFF9FFCC] flex justify-between items-center p-3 w-[182px] gap-2">
                    <div>
                      <img
                        src={collaborator.img}
                        alt={collaborator.name}
                        className="object-center object-contain"
                      />
                    </div>
                    <div>
                      <h3 className="text-[12px] font-[500] font-inter">
                        {collaborator.name}
                      </h3>
                      <p className="text-[#1B1B1B] font-inter text-[10px]">
                        {collaborator.email}
                      </p>
                    </div>
                    <div>&times;</div>
                  </div>
                ))}
              </div>
              <div className="mt-16 flex justify-center items-center">
                <Button
                  className="inline-flex justify-center items-center gap-2 rounded-md bg-gradient-to-r from-purple-custom to-blue-custom py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white data-[open]:bg-gray-700 w-[181px]"
                  onClick={handleCollaboratorSuccessful}
                >
                  Close
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <ActionCompleted
        isOpen={isActionCompletedOpen}
        onClose={handleActionCompletedClose}
      />
    </div>
  );
};

export default AllCollaborator