// ListView.js
import React, { useState } from "react";
import AllDocuments from "./AllDocuments";
import ActionsFile from "./ActionFile";
import FileTable from "./FileTable";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiFolderOn } from "react-icons/ci";
import ActionsDocument from "./ActionDocument";

export const ListView = ({ folders = [], files = [] }) => {
  const [openModalId, setOpenModalId] = useState(null);

  const toggleModal = (type, id) => {
    const modalId = `${type}-${id}`;
    setOpenModalId(openModalId === modalId ? null : modalId);
  };

  console.log("Folders:", folders);
  console.log("Files:", files);

  return (
    <div>
      {/* Folder Table */}
      <FileTable>
        {folders.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              <CiFolderOn size={20} className="text-[#5608D4]" />
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.name}  
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.owner}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="text-[14px] py-4 px-4 text-left">
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal("folder", item.id)}
              />
              {/* Action Modal for Folders */}
              {openModalId === `folder-${item.id}` && (
                <ActionsFile onClose={() => setOpenModalId(null)} />
              )}
            </td>
          </tr>
        ))}
      </FileTable>

      <AllDocuments files={files} />

      {/* File Table */}
    <FileTable>
        {files.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              <img src={item.fileExtension} alt={item.name} />
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.name}
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.owner}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>   
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="py-4 px-4 text-left">
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal("file", item.id)}
              />
              {openModalId === `file-${item.id}` && (
                <ActionsDocument onClose={() => setOpenModalId(null)} />
              )}
            </td>
          </tr>
        ))}
      </FileTable>
    </div>
  );
};

export default ListView;
