import React from 'react'
// import FileCard from '../cards/FileCard';
import DocumentsCard from '../cards/DocumentsCard';
const AllDocBoardView = ({ folders, openModalId,  toggleModal}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mb-6 p-4">
      {folders.map((file) => (
        <DocumentsCard
          key={file.id}
          id={file.id}
          title={file.title}
          folderImg={file.folderImg}
          lastModified={file.lastModified}
          owner={file.name}
          fileType={file.fileType}
          toggleModal={() => toggleModal(file.id)}
          isOpen={openModalId === file.id}
        />
      ))}
    </div>
  );
};

export default AllDocBoardView