import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAuthStore from "../../data/Stores/Authstore";
import Vector from "../../assets/Vector.png";
import arrowdown from "../../assets/arrowdown.png";

const PagesList = () => {
  const [isEmployees, setIsEmployees] = useState(false);
  const [isEngagement, setIsEngagement] = useState(false);
  const [isLeave, setIsLeave] = useState(false);
  const [addRecruitment, setAddRecruitment] = useState(false);

  const handleEmployees = () => {
    setIsEmployees(!isEmployees);
  };
  const closeEmployee = () => {
    setIsEmployees(false);
  };
  const handleEngagement = () => {
    setIsEngagement(!isEngagement);
  };
  const closeEngagement = () => {
    setIsEngagement(false);
  };

  const handleLeave = () => {
    setIsLeave(!isLeave);
  };

  const handleRecruitment = () => {
    setAddRecruitment(!addRecruitment);
  };
  const closeRecruitment = () => {
    setAddRecruitment(false);
  };

  const closeLeave = () => {
      setIsLeave(false);
    },
    { page } = useParams(),
    { user } = useAuthStore();

  return (
    <div className=" w-full grid place-items-center">
      <ul className=" w-full lg:flex grid md:grid-cols-6 grid-cols-3 lg:gap-6 md:gap-0 gap-5  relative justify-center items-center bg-white pl-12 pr-20 py-4">
        <Link
          to={user?.isAdmin ? "/dashboard" : "/employee-dashboard"}
          className={` text-xs cursor-pointer ${
            ["dashboard", "employee-dashboard"]?.includes(page)
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Dashboard
        </Link>
        <div className=" flex items-center gap-1 relative">
          <li
            onClick={handleEmployees}
            className={` text-xs cursor-pointer flex items-center ${
              ["manage-employees"]?.includes(page)
                ? "text-[#080FAE] font-Kumbh"
                : "text-[#1B1B1B] Satoshi"
            }`}
          >
            Employees
          </li>

          <img
            onClick={handleEmployees}
            className=" w-[16px] mt-[0.9px] h-[7.48px] cursor-pointer  "
            src={arrowdown}
            alt=""
          />
        </div>

        {isEmployees && (
          <div
            onClick={closeEmployee}
            className=" fixed z-30 top-0 left-0 bottom-0  w-full h-full bg-black bg-opacity-45"
          >
            <div className=" absolute top-32 left-32 grid gap-3 bg-white w-56 p-5 h-auto">
              <Link
                to="/manage-employees"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/Change.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  All Employee
                </p>
              </Link>
              <Link
                to="/manage-employees/onboarding"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/Commitment.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  Onboarding
                </p>
              </Link>
              <Link
                to="/manage-employees/offboarding"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/Belongings.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  Offboarding
                </p>
              </Link>
            </div>
          </div>
        )}

        <div className=" flex items-center gap-1">
          <li
            onClick={handleEngagement}
            className={`text-xs cursor-pointer ${
              ["announcements", "posts"]?.includes(page)
                ? "text-[#080FAE] font-Kumbh"
                : "text-[#1B1B1B] Satoshi"
            }`}
          >
            Engagement
          </li>
          <img
            onClick={handleEngagement}
            className=" w-[16px] mt-[0.9px] h-[7.48px] cursor-pointer  "
            src={arrowdown}
            alt=""
          />
        </div>
        {isEngagement && (
          <div
            onClick={closeEngagement}
            className=" fixed z-30 top-0 left-0 bottom-0  w-full h-full bg-black bg-opacity-45"
          >
            <div className=" absolute top-32 left-64 grid gap-3 bg-white w-56 p-5 h-auto">
              <Link
                to="/announcements"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-5 h-5"
                  src={require("../../assets/Announcements.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  Announcements
                </p>
              </Link>
              <Link
                to="/posts"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/Posts.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  Posts
                </p>
              </Link>
            </div>
          </div>
        )}
        <div className=" flex items-center gap-1">
          <li
            onClick={handleLeave}
            className={`text-xs cursor-pointer ${
              page === "leave"
                ? "text-[#080FAE] font-Kumbh"
                : "text-[#1B1B1B] Satoshi"
            }`}
          >
            Leave
          </li>
          <img
            onClick={handleLeave}
            className=" w-[16px] mt-[0.9px] h-[7.48px] cursor-pointer  "
            src={arrowdown}
            alt=""
          />
        </div>
        {isLeave && (
          <div
            onClick={closeLeave}
            className=" fixed z-30 top-0 left-0 bottom-0  w-full h-full bg-black bg-opacity-45"
          >
            <div className=" absolute top-32 left-64 grid gap-3 bg-white w-56 p-5 h-auto">
              <Link
                to="/leave/manage-request"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-5 h-5"
                  src={require("../../assets/Leavelocation.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  Manage Leaves
                </p>
              </Link>
              <Link
                to="/leave/my-requests"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/LeaveRequest.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  My Requests
                </p>
              </Link>
            </div>
          </div>
        )}
        <li
          className={` text-xs cursor-pointer ${
            page === "finance"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Finance
        </li>
        {/* <li clasName={`" text-[#1B1B1B] Satoshi text-xs cursor-pointer ${l === '' ? "":""}`}>
          Inventory
        </li> */}
        <div className=" flex items-center gap-1">
          <li
            onClick={handleRecruitment}
            className={` text-xs cursor-pointer ${
              page === "recruitment"
                ? "text-[#080FAE] font-Kumbh"
                : "text-[#1B1B1B] Satoshi"
            }`}
          >
            Recruitment
          </li>
          <img
            onClick={handleRecruitment}
            className=" w-[16px] mt-[0.9px] h-[7.48px] cursor-pointer  "
            src={arrowdown}
            alt=""
          />
        </div>
        {addRecruitment && (
          <div
            onClick={closeRecruitment}
            className=" fixed z-30 top-0 left-0 bottom-0  w-full h-full bg-black bg-opacity-45"
          >
            <div className=" absolute top-32 left-[600px] grid gap-3 bg-white w-56 p-5 h-auto">
              <Link
                to="/recruitment"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-5 h-5"
                  src={require("../../assets/dicons.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  All Jobs
                </p>
              </Link>
              <Link
                to="/recruitment/all-candidates"
                className=" flex items-center gap-3 cursor-pointer"
              >
                <img
                  className=" w-6 h-6"
                  src={require("../../assets/t3dicons.png")}
                  alt=""
                />
                <p className=" font-normal font-Nunito text-xs text-[#262626]">
                  All Candidates
                </p>
              </Link>
            </div>
          </div>
        )}
        <Link
          to="/requests"
          className={` text-xs cursor-pointer ${
            page === "requests"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Request
        </Link>
        <Link
          to="/attendance-management"
          className={` text-xs cursor-pointer ${
            page === "attendance-management"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Attendance
        </Link>
        <Link
          to="/performance"
          className={` text-xs cursor-pointer ${
            page === "performance"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Performance
        </Link>
        <li
          className={` text-xs cursor-pointer ${
            page === "policy"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          HR Policy
        </li>
        <Link
          to="/company-settings"
          className={` text-xs cursor-pointer ${
            page === "company-settings"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Company settings
        </Link>
        <Link
          to="/tasks"
          className={` text-xs cursor-pointer ${
            page === "tasks"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Tasks
        </Link>
        <Link
          to="/resources"
          className={` text-xs cursor-pointer ${
            page === "tasks"
              ? "text-[#080FAE] font-Kumbh"
              : "text-[#1B1B1B] Satoshi"
          }`}
        >
          Resources
        </Link>
      </ul>
    </div>
  );
};

export default PagesList;
