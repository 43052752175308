import React from "react";
import { Dialog } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";

export default function AddFolderModal({
  title,
  children,
  isOpen,
  onClose,
  onCreate, 
}) {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        className="fixed inset-0 z-50 flex items-center justify-center"
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-50"
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <div className="relative bg-white w-[738px] mx-auto p-8 rounded-lg">
          <div className="flex justify-between items-center mb-6">
            <Dialog.Title className="text-xl text-[#000000] font-[600] font-Kumbh">
              {title}
            </Dialog.Title>
            <button
              onClick={onClose}
              className="text-[#272525] text-xl font-bold"
            >
              <IoMdClose />
            </button>
          </div>

          <form>
            {children}
            <button
              type="button"
              onClick={onCreate}
              className="w-[181px] px-5 py-2 rounded-lg bg-gradient-to-r from-[#5608D4] to-[#384295] text-white font-semibold transition ease-in-out hover:opacity-90"
            >
              Create
            </button>
          </form>
        </div>
      </Dialog>
    </>
  );
}
