import React, { useState } from "react";
import AddFolderModal from "../resources/AddFolderModal";
import ActionCompleted from "../cards/ActionCompleted";

const AddFileModal = ({ onClose }) => {
  const [addFile, setAddFile] = useState({
    fileName: "",
    description: "",
  });

  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState(true); 
  const [isActionCompletedOpen, setIsActionCompletedOpen] = useState(false); 

  const handleCreateClick = () => {
    setIsAddFileModalOpen(false); 
    setIsActionCompletedOpen(true); 
  };

  const handleActionCompletedClose = () => {
    setIsActionCompletedOpen(false); 
    onClose(); 
  };

  const handleChange = (ev) => {
    setAddFile({
      ...addFile,
      [ev.target.name]: ev.target.value,
    });
  };

  const { fileName, description } = addFile;

  return (
    <div>
      {/* Add Folder Modal */}
      {isAddFileModalOpen && (
        <AddFolderModal
          title="Add File"
          isOpen={true}
          onClose={onClose}
          onCreate={handleCreateClick} // Trigger Action Completed
        >
          {/* File Name Input */}
          <div className="flex flex-col gap-3 my-8">
            <label
              htmlFor="current-name"
              className="text-[#344054] text-[14px] font-Nunito font-[500]"
            >
              Current name
            </label>
            <input
              type="text"
              id="current-name"
              placeholder="Data Survey"
              className="w-full border border-[#E0E0E0] text-[13px] text-[#101828] font-Nunito p-2 rounded-lg focus:outline-none focus:border-[#5608D4] focus:ring-1 focus:ring-[#5608D4]"
              name="fileName"
              value={fileName}
              onChange={handleChange}
            />
          </div>

          {/* Folder Description Input */}
          <div className="mb-4">
            <label className="text-sm text-[#121212] font-Nunito mb-1 block">
              Folder Description
            </label>
            <textarea
              placeholder="Description"
              rows={4}
              className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
              name="description"
              value={description}
              onChange={handleChange}
            ></textarea>
          </div>
        </AddFolderModal>
      )}
    </div>
  );
};

export default AddFileModal;
