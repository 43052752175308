import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoOpenOutline } from "react-icons/io5";
import { BsVr } from "react-icons/bs";
import { VscNewFile } from "react-icons/vsc";
import { HiOutlineShare } from "react-icons/hi2";
import Collaborator from "../../assets/carbon_collaborate.png";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import RenameModal from "../cards/RenameModal";
import AddFileModal from "../cards/AddFileModal";
import ShareFileModal from "./ShareFileModal";
import AllCollaborator from "./AllCollaborator";
import FolderDetail from "./FolderDetails";
import RemoveFolder from "./RemoveFolder";

export default function ActionsFile({ onClose, style }) {
    const [isModalOpenRename, setIsModalOpenRename] = useState(false);
    const [isModalShareFile, setIsModalShareFile] = useState(false);
    const [isModalAllCollaborator, setIsModalAllCollaborator] = useState(false);
    const [isModalFolderDetail, setIsModalFolderDetail] = useState(false);
    const [isModalRemoveFolder, setIsModalRemoveFolder] = useState(false);

    // Modal Handlers
    const handleOpenModalRename = () => {
      setIsModalOpenRename(true);
      // onClose();
    };

    const handleCloseModalRename = () => {
      setIsModalOpenRename(false);
    };
    
    const handleOpenModalShareFile = () => {
      setIsModalShareFile(true);
    };

    const handleCloseModalShareFile = () => {
      setIsModalShareFile(false);
    };

    const handleOpenModalAllCollaborator = () => {
      setIsModalAllCollaborator(true);
    };

    const handleCloseModalAllCollaborator = () => {
      setIsModalAllCollaborator(false);
    };

    const handleOpenModalFolderDetail = () => {
      setIsModalFolderDetail(true);
    };

    const handleCloseModalFolderDetail = () => {
      setIsModalFolderDetail(false);
    };

    const handleOpenModalRemoveFolder = () => {
      setIsModalRemoveFolder(true);
    };

    const handleCloseRemoveFolder = () => {
      setIsModalRemoveFolder(false);
    };
  return (
    <>
      <div
        className="fixed inset-0 bg-transparent"
        onClick={onClose}
        aria-hidden="true"
      ></div>

      {/* Action Menu */}
      <div
        className="absolute flex flex-col gap-4 right-2 top-6 bg-white shadow-lg w-[200px] rounded-lg z-50 border p-2"
        style={style}
        onClick={(e) => e.stopPropagation()}
      >
        <Link
          to="alldocument"
          className="flex items-center gap-3 cursor-pointer text-[12px]"
        >
          <IoOpenOutline size={18} />
          <p>Open</p>
        </Link>
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px]"
          onClick={handleOpenModalRename}
        >
          <BsVr size={18} />
          <p>Rename</p>
        </div>
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px]"
          onClick={handleOpenModalShareFile}
        >
          <HiOutlineShare size={18} />
          <p>Share</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px]" onClick={handleOpenModalShareFile}>
          <AiOutlineExclamationCircle size={18} />
          <p>Details</p>
        </div>
        <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#DD1F1F]" onClick={handleOpenModalRemoveFolder}>
          <RiDeleteBin6Line size={18} />
          <p>Remove</p>
        </div>
      </div>

      {/* Modals */}
      {isModalOpenRename && <RenameModal onClose={handleCloseModalRename} />}
      {isModalShareFile && (
        <ShareFileModal onClose={handleCloseModalShareFile} />
      )}
      {isModalAllCollaborator && (
        <AllCollaborator onClose={handleCloseModalAllCollaborator} />
      )}
      {isModalFolderDetail && (
        <FolderDetail onClose={handleCloseModalFolderDetail} />
      )}

      {isModalRemoveFolder && (
        <RemoveFolder onClose={handleCloseRemoveFolder} />
      )}
    </>
  );
}
