import { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import ActionCompleted from "./ActionCompleted";

export default function RenameModal({ onClose }) {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(true);
  const [isActionCompletedOpen, setIsActionCompletedOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("")

  const handleRenameSuccessful = () => {
    setIsRenameModalOpen(false); 
    setIsActionCompletedOpen(true); 
  };

  const handleActionCompletedClose = () => {
    setIsActionCompletedOpen(false);
    onClose(); 
  };

  return (
    <>
      <Dialog
        as="div"
        className="relative z-50"
        open={isRenameModalOpen}
        onClose={onClose}
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-30"
          aria-hidden="true"
        />
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          <DialogPanel className="w-full max-w-[600px] rounded-xl bg-white p-6 shadow-lg">
            <p
              className="float-right text-3xl cursor-pointer "
              onClick={onClose}
            >
              &times;
            </p>

            <DialogTitle className="text-lg text-[20px] font-medium font-inter text-[#1D2939] mt-6">
              Rename 'Title'
            </DialogTitle>
            <p className="mt-2 text-sm text-[#475467]">
              Edit the current name to the new name you want
            </p>

            <div className="flex flex-col gap-3 my-8">
              <label
                htmlFor="current-name"
                className="text-[#344054] text-[14px] font-Nunito font-[500]"
              >
                Current name
              </label>
              <input
                type="text"
                id="current-name"
                placeholder="Data Survey"
                className="w-full border border-[#E0E0E0] text-[13px] text-[#101828] font-Nunito p-2 rounded-lg focus:outline-none focus:border-[#5608D4] focus:ring-1 focus:ring-[#5608D4]"
                value={isEdit}
                onChange={(ev) => setIsEdit(ev.target.value)}
              />
            </div>

            <div className="mt-4 flex justify-between gap-2">
              <button
                onClick={onClose}
                className="text-[#5608D4] text-[16px] font-[600] font-inter w-[264px] py-2 px-4 rounded-lg border border-[#5608D4]"
              >
                Cancel
              </button>
              <button
                className="bg-[#5608D4] font-[600] w-[264px] text-white py-2 px-4 rounded-lg"
                onClick={handleRenameSuccessful}
              >
                Rename
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <ActionCompleted
        isOpen={isActionCompletedOpen}
        onClose={handleActionCompletedClose}
      />
    </>
  );
}
