import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";

const OptionModal = ({ title, children, onClose }) => (
  <Dialog as="div" className="relative z-50" open={true} onClose={onClose}>
    <div className="fixed inset-0 bg-black bg-opacity-30" aria-hidden="true" />
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <DialogPanel className="w-full max-w-[384px]  bg-white p-3 shadow-lg overscroll-y-auto">
        <div className="flex items-center justify-between">
          <DialogTitle className="text-lg text-[20px] font-[600] font-inter text-[#000000]">
            {title}
          </DialogTitle>
            <div
              className="text-[#000] text-[24px] font-medium cursor-pointer font-Nunito py-2 px-4 rounded-lg"
              onClick={onClose}
            >
              &times;
            </div>
        </div>
          {children}
      </DialogPanel>
    </div>
  </Dialog>
);

export default OptionModal;
