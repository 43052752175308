import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoOpenOutline } from "react-icons/io5";
import { BsVr } from "react-icons/bs";
import { VscNewFile } from "react-icons/vsc";
import { HiOutlineShare } from "react-icons/hi2";
import Collaborator from "../../assets/carbon_collaborate.png";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import RenameModal from "../cards/RenameModal";
import AddFileModal from "../cards/AddFileModal";
import ShareFileModal from "./ShareFileModal";
import AllCollaborator from "./AllCollaborator";
import FolderDetail from "./FolderDetails";
import RemoveFolder from "./RemoveFolder"

// ActionFolder Component
export function ActionFolder({ onClose }) {
  const [isModalOpenRename, setIsModalOpenRename] = useState(false);
  const [isModalAddFile, setIsModalAddFile] = useState(false);
  const [isModalShareFile, setIsModalShareFile] = useState(false);
  const [isModalAllCollaborator, setIsModalAllCollaborator] = useState(false);
  const [isModalFolderDetail, setIsModalFolderDetail] = useState(false);
  const [isModalRemoveFolder, setIsModalRemoveFolder] =useState(false);

  // Modal Handlers
  const handleOpenModalRename = () => {
    setIsModalOpenRename(true);
    // onClose();
  };

  const handleCloseModalRename = () => {
    setIsModalOpenRename(false);
  };

  const handleOpenModalAddFile = () => {
    setIsModalAddFile(true);
    // onClose();
  };

  const handleCloseModalAddFile = () => {
    setIsModalAddFile(false);
  };

  const handleOpenModalShareFile = () => {
    setIsModalShareFile(true);
  };

  const handleCloseModalShareFile = () => {
    setIsModalShareFile(false);
  };

  const handleOpenModalAllCollaborator = () => {
    setIsModalAllCollaborator(true);
  };

  const handleCloseModalAllCollaborator = () => {
    setIsModalAllCollaborator(false);
  };

  const handleOpenModalFolderDetail = () => {
    setIsModalFolderDetail(true);
  };

  const handleCloseModalFolderDetail = () => {
    setIsModalFolderDetail(false);
  };

const handleOpenModalRemoveFolder = () => {
  setIsModalRemoveFolder(true)
}

const handleCloseRemoveFolder = () => {
  setIsModalRemoveFolder(false)
}

  const handleClickOverlay = (e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    onClose();
  };

  return (
    <>
      {/* Overlay */}
      <div
        className="fixed inset-0 bg-transparent"
        onClick={handleClickOverlay}
        aria-hidden="true"
      ></div>

      {/* Action Folder Modal */}
      <div
        className="absolute flex flex-col gap-6 right-2 top-8 bg-white shadow-lg w-[230px] h-[302px] rounded-lg z-30 border border-[#EBEEF2] p-2"
        onClick={(e) => e.stopPropagation()}
      >
        <Link to="listviewfiles">
          <div className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]">
            <IoOpenOutline size={18} className="text-[#1B1B1BB2]" />
            <p>Open</p>
          </div>
        </Link>

        {/* Rename Action */}
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]"
          onClick={handleOpenModalRename}
        >
          <BsVr size={18} className="text-[#1B1B1BB2]" />
          <p>Rename</p>
        </div>

        {/* Add File Action */}
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]"
          onClick={handleOpenModalAddFile}
        >
          <VscNewFile size={18} className="text-[#1B1B1BB2]" />
          <p>Add file</p>
        </div>

        {/* Other Actions */}
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]"
          onClick={handleOpenModalShareFile}
        >
          <HiOutlineShare size={18} className="text-[#1B1B1BB2]" />
          <p>Share</p>
        </div>
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]"
          onClick={handleOpenModalAllCollaborator}
        >
          <img src={Collaborator} alt="Collaborator" className="w-4 h-4" />
          <p>Collaborate</p>
        </div>
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#475467]"
          onClick={handleOpenModalFolderDetail}
        >
          <AiOutlineExclamationCircle size={18} className="text-[#1B1B1BB2]" />
          <p>Details</p>
        </div>
        <div
          className="flex items-center gap-3 cursor-pointer text-[12px] text-[#DD1F1F]"
          onClick={handleOpenModalRemoveFolder}
        >
          <RiDeleteBin6Line size={18} className="text-[#DD1F1F]" />
          <p>Remove</p>
        </div>
      </div>

      {/* Modals */}
      {isModalOpenRename && <RenameModal onClose={handleCloseModalRename} />}
      {isModalAddFile && <AddFileModal onClose={handleCloseModalAddFile} />}
      {isModalShareFile && (
        <ShareFileModal onClose={handleCloseModalShareFile} />
      )}
      {isModalAllCollaborator && (
        <AllCollaborator onClose={handleCloseModalAllCollaborator} />
      )}
      {isModalFolderDetail && (
        <FolderDetail onClose={handleCloseModalFolderDetail} />
      )}

      {isModalRemoveFolder && (
        <RemoveFolder onClose={handleCloseRemoveFolder} />
      )}
    </>
  );
}

// ActionsFile Component

