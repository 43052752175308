import React, { useState } from "react";
import FileCard from "../cards/FileCard";
import AllDocuments from "./AllDocuments";
import DocumentsCard from "../cards/DocumentsCard";
import GoogleDoc from "../../assets/google-docs.png";
import Document from "../../assets/document.png";
import Word from "../../assets/word.png";
import PPTX from "../../assets/pptx.png";

export default function BoardView() {
  const [openModalId, setOpenModalId] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [searchTerm, setSearchTerm] = useState("");

  const toggleModal = (id, event = null) => {
  if (event && typeof event.stopPropagation === "function") {
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setModalPosition({ top: rect.top + window.scrollY, left: rect.left });
  }
  setOpenModalId(openModalId === id ? null : id);
};

  const files = [
    {
      id: 1,
      title: "Data Privacy and Security Plans",
      folderImg: GoogleDoc,
      name: "Catherine Jacobs",
      date: "26/03/2024",
    },
    {
      id: 2,
      title: "Data Privacy and Security Policies",
      folderImg: GoogleDoc,
      name: "Catherine Jacobs",
      date: "26/03/2024",
    },
    {
      id: 3,
      title: "Compliance Checklist",
      folderImg: GoogleDoc,
      name: "John Doe",
      date: "28/03/2024",
    },
    {
      id: 4,
      title: "User Feedback Report",
      folderImg: GoogleDoc,
      name: "Alice Johnson",
      date: "30/03/2024",
    },
    {
      id: 5,
      title: "Development Plan",
      folderImg: GoogleDoc,
      name: "Mike Tyson",
      date: "01/04/2024",
    },
    {
      id: 6,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
  ];

  const documents = [
    {
      id: 11,
      title: "Data Survey Form.docx",
      folderImg: Document,
      lastModified: "10:24 am",
      owner: "Catherine Jacobs",
      fileType: Word,
    },
    {
      id: 22,
      title: "Implementation Policies.pptx",
      folderImg: Document,
      lastModified: "10:24 am",
      owner: "Catherine Jacobs",
      fileType: PPTX,
    },
    {
      id: 33,
      title: "Data Survey Form.docx",
      folderImg: Document,
      lastModified: "10:24 am",
      owner: "Catherine Jacobs",
      fileType: Word,
    },
    {
      id: 44,
      title: "Implementation Policies.pptx",
      folderImg: Document,
      lastModified: "10:24 am",
      owner: "Catherine Jacobs",
      fileType: PPTX,
    },
    {
      id: 55,
      title: "Data Survey Form.docx",
      folderImg: Document,
      lastModified: "10:24 am",
      owner: "Catherine Jacobs",
      fileType: Word,
    },
  ];

  const filteredDocuments = documents.filter((file) =>
    file.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

 return (
    <div className="p-4">
      {/* Files Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mb-6">
        {files.map((file) => (
          <FileCard
            key={file.id}
            id={file.id}
            title={file.title}
            folderImg={file.folderImg}
            name={file.name}
            date={file.date}
            toggleModal={(event) => toggleModal(file.id, event)}
            isOpen={openModalId === file.id} // Only open the modal if IDs match
          />
        ))}
      </div>

      <AllDocuments />

      {/* Documents Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
        {filteredDocuments.map((file) => (
          <DocumentsCard
            key={file.id}
            id={file.id}
            title={file.title}
            folderImg={file.folderImg}
            lastModified={file.lastModified}
            owner={file.owner}
            fileType={file.fileType}
            toggleModal={(event) => toggleModal(file.id, event)}
            isOpen={openModalId === file.id} // Only open the modal if IDs match
          />
        ))}
      </div>
    </div>
  );
}
