import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import ListViewPage from "../../components/resources/ListViewPage";
import BoardViewPage from "../../components/resources/BoardViewPage";
import AddFolderModal from "../../components/resources/AddFolderModal";
import { IoMdMenu } from "react-icons/io";
import { RxDashboard } from "react-icons/rx";
import { BsSearch } from "react-icons/bs";
import FileUploadPreview from "../../components/resources/FileUploadPreview";
import GoogleDoc from "../../assets/google-docs.png";


const ListViewFiles = () => {
  const [openModalId, setOpenModalId] = useState(null);
  const [activeTab, setActiveTab] = useState("list-view");
  const [searchQuery, setSearchQuery] = useState("");

  let [isOpen, setIsOpen] = useState(false);

  const toggleModal = (id) => {
    setOpenModalId(openModalId === id ? null : id);
  };

  function handleSwitchTab(tab) {
    setActiveTab(tab);
  }


    function open() {
      setIsOpen(!isOpen);
    }

    function close() {
      setIsOpen(!isOpen);
    }

  const foldersListView = [
    {
      id: 1,
      name: "Internal Surveys & Feedback Forms",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 2,
      name: "Project Reports",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 3,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 4,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 5,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 6,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 7,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 8,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
    {
      id: 9,
      name: "Team Meeting Notes",
      owner: "Catherine Jacobs",
      createdOn: "26th March, 2024",
      lastModified: "26th March, 2024",
      modifiedBy: "Debs Gabriel",
    },
  ];

  const foldersBoardView = [
    {
      id: 1,
      title: "Data Privacy and Security Plans",
      folderImg: GoogleDoc,
      name: "Catherine Jacobs",
      date: "26/03/2024",
    },
    {
      id: 2,
      title: "Data Privacy and Security Policies",
      folderImg: GoogleDoc,
      name: "Catherine Jacobs",
      date: "26/03/2024",
    },
    {
      id: 3,
      title: "Compliance Checklist",
      folderImg: GoogleDoc,
      name: "John Doe",
      date: "28/03/2024",
    },
    {
      id: 4,
      title: "User Feedback Report",
      folderImg: GoogleDoc,
      name: "Alice Johnson",
      date: "30/03/2024",
    },
    {
      id: 5,
      title: "Development Plan",
      folderImg: GoogleDoc,
      name: "Mike Tyson",
      date: "01/04/2024",
    },
    {
      id: 6,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 7,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 8,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 9,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 10,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 11,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
    {
      id: 12,
      title: "Testing Strategy",
      folderImg: GoogleDoc,
      name: "Nina Brown",
      date: "02/04/2024",
    },
  ];

  return (
    <div className="bg-[#F5F7F8] ">
      <Navbar />
      <PagesList />
      <div className="flex items-center gap-4 my-3 px-6 lg:px-12 pt-4">
        <p className="font-Kumbh text-[#72618d] text-base font-bold">
          Documents
        </p>
        <p className="font-Kumbh text-[#000] text-base font-[500]">Assets</p>
      </div>
      <div className="mx-auto lg:mx-12 bg-white min-h-screen">
        <div className="flex items-center gap-6 p-5">
          <p className="font-Satoshi text-[#000000] text-[15px] font-medium">
            Folder name
          </p>
        </div>

        <div className="flex flex-col lg:flex-row justify-between items-center mb-6 gap-4 lg:gap-0 px-5">
          <div className="flex flex-col lg:flex-row justify-between items-center gap-4 lg:gap-x-36 w-full lg:w-auto">
            <div className="w-full lg:w-auto text-left lg:text-center">
              <h2 className="text-[15px] text-[#000000] font-[500]">
                All Files
              </h2>
            </div>

            {/* List and Board View Toggle for Desktop */}
            <div className="hidden lg:flex items-center">
              <button
                onClick={() => setActiveTab("list-view")}
                className={`flex items-center gap-2 border-2 border-[#EBEEF2] px-2 py-3 w-[117px] rounded-e-lg cursor-pointer ${
                  activeTab === "list-view" ? "bg-[#F7F8FA]" : "bg-[#FFFFFF]"
                }`}
              >
                <IoMdMenu sizes={24} className="text-[#C7CED9]" />
                <span className="text-sm text-[#606C80] font-[500]">
                  List View
                </span>
              </button>

              <button
                onClick={() => setActiveTab("board-view")}
                className={`flex items-center gap-2 border-2 border-[#EBEEF2] px-2 py-3 w-[130px] rounded-s-lg cursor-pointer ${
                  activeTab === "board-view" ? "bg-[#F7F8FA]" : "bg-[#FFFFFF]"
                }`}
              >
                <RxDashboard className="text-[#C7CED9]" />
                <span className="text-[#606C80] text-sm font-[500]">
                  Board View
                </span>
              </button>
            </div>

            {/* Dropdown for Mobile View */}
            <div className="block lg:hidden w-full">
              <select
                className="w-full border border-[#EBEEF2] px-2 py-3 rounded-lg text-[#606C80] font-[500]"
                onChange={(e) => setActiveTab(e.target.value)}
              >
                <option value="list-view">List View</option>
                <option value="board-view">Board View</option>
              </select>
            </div>

            {/* Search Bar */}
            <div className="flex items-center gap-4 w-full lg:w-auto">
              <span className="text-[#121212] font-Nunito hidden lg:flex">
                Search documents
              </span>
              <div className="flex items-center justify-between gap-2 border border-[#ACC3E7] px-3 py-2 rounded-lg w-full lg:w-auto">
                <input
                  type="text"
                  className="hidden lg:block   border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                  placeholder="Search"
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                />
                <input
                  type="text"
                  className="flex lg:hidden border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                  placeholder="Search for a document"
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                />
                <BsSearch className="text-[#000000]" />
              </div>
            </div>
          </div>

          {/* Add New Button */}
          <div className="flex items-center justify-end lg:justify-start w-full lg:w-auto mr-0 lg:mr-20">
            <button
              onClick={open}
              className="text-[#5608D4] border border-[#5608D4] hover:bg-[#5608D4] hover:text-white transition ease-in-out focus:ring-2 text-base font-[500] px-4 py-2 rounded-lg w-full lg:w-auto"
            >
              + Add new
            </button>

            {/* Add New Folder Modal */}
            <AddFolderModal isOpen={isOpen} onClose={close} title="Add File">
              <div className="flex flex-col lg:flex-row gap-4 mb-4">
                <div className="w-full">
                  <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                    Folder Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name"
                    className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
                  />
                </div>

                {/* Send to */}
                <div className="w-full">
                  <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                    Send to
                  </label>
                  <select className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]">
                    <option value="">Choose recipient</option>
                    <option value="team">Team</option>
                    <option value="department">Department</option>
                  </select>
                </div>
              </div>

              {/* Folder Description */}
              <div className="mb-4">
                <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Folder Description
                </label>
                <textarea
                  placeholder="Description"
                  rows={4}
                  className="w-full border border-[#E0E0E0] p-2 rounded-lg focus:outline-none focus:border-[#5608D4]"
                ></textarea>
              </div>

              {/* Share by Options */}
              <div className="mb-6">
                <p className="text-sm text-[#121212] font-Nunito mb-1 block">
                  Share by:
                </p>
                <div className="flex flex-col gap-2">
                  {["Department", "Level", "Grade", "Position"].map(
                    (option) => (
                      <label key={option} className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="shareBy"
                          value={option.toLowerCase()}
                          className="form-radio text-[#5608D4]"
                        />
                        <span className="text-[12px] text-[#121212] font-Nunito">
                          {option}
                        </span>
                      </label>
                    )
                  )}
                </div>

                {/* File Upload Section */}
                <div className="my-6">
                  <label className="text-sm text-[#121212] font-Nunito mb-1 block">
                    Upload Files
                  </label>
                  <FileUploadPreview />
                </div>
              </div>
            </AddFolderModal>
          </div>
        </div>

        {activeTab === "list-view" && (
          <ListViewPage
            folders={foldersListView}
            openModalId={openModalId}
            toggleModal={toggleModal}
          />
        )}
        {activeTab === "board-view" && (
          <BoardViewPage
            folders={foldersBoardView}
            openModalId={openModalId}
            toggleModal={toggleModal}
          />
        )}
      </div>
    </div>
  );
};

export default ListViewFiles;
