import React from "react";
import { Button, Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import DeleteFolder from "../../assets/deleteFolder.png";

const Successful = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      as="div"
      className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center"
      onClose={onClose}
    >
      <div className="fixed inset-0 z-50 w-screen bg-black bg-opacity-30 overflow-y-auto">
        <div className="flex flex-col min-h-full items-center justify-center p-4">
          <DialogPanel className="w-full flex justify-center items-center flex-col max-w-[300px] h-[300px] rounded-xl bg-white p-6 shadow-lg">
            <img src={DeleteFolder} alt="delete files" />
            <DialogTitle
              as="p"
              className="mt-2 font-Nunito font-[800] text-black"
            >
              “Item Type’
            </DialogTitle>
            <p className="text-[14px]">deleted successfully!</p>
            {/* <Button
              className="rounded-md text-center bg-gradient-to-r from-purple-custom w-[79px] to-blue-custom py-1.5 px-3 text-sm font-semibold text-white mt-10"
              onClick={onClose}
            >
              Close
            </Button> */}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Successful;
