import React from "react";
import FileTable from "../../components/resources/FileTable";
import ActionsFile from "./ActionFile";
// import ActionAllFiles from "./ActionAllFiles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CiFolderOn } from "react-icons/ci";

const ListViewPage = ({ folders, openModalId, toggleModal }) => {
  return (
    <div>
      <FileTable>
        {folders.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              <CiFolderOn size={20} className="text-[#5608D4]" />
              {/* <img src={item.fileExtension} alt={item.name} /> */}
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.name}
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.owner}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="py-4 px-4 text-left relative">
              {/* Action Icon */}
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal(`file-${item.id}`)}
              />
              {/* Modal for Actions */}
              {openModalId === `file-${item.id}` && (
                <div className="absolute right-0 top-8 z-10">
                  <ActionsFile onClose={() => toggleModal(null)} />
                </div>
              )}
            </td>
          </tr>
        ))}
      </FileTable>
    </div>
  );
};

export default ListViewPage;
