import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../components/navbar/Navbar";
import PagesList from "../../../components/pages-list/PagesList";
import { BsSearch } from "react-icons/bs";
import Laptop from "../../../assets/Laptop2.png"



const Assign = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const tools = [
    {
      id: 1,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 2,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 3,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 4,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 5,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 6,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 7,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 8,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 9,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
    {
      id: 10,
      img: Laptop,
      assetName: "Laptop",
      assetsId: "001A",
      detail: "Tool Details",
      tools: "HP ELITEBOOK, 16GB RAM, SSD",
    },
  ];

  const filteredTools = tools.filter((item) =>
    item.assetName && item.assetName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedTools = filteredTools.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const totalPages = Math.ceil(filteredTools.length / itemsPerPage);

  return (
    <div className="bg-[#F5F7F8] ">
      <Navbar />
      <PagesList />
      <div className="flex items-center gap-4 px-6 lg:px-12 pt-4 mb-4">
        <Link
          to="/resources"
          className="font-Kumbhtext-[#000] text-base font-[500]"
        >
          Files Management
        </Link>
        <Link
          to="/resources/assetmanagement"
          className="font-Kumbh text-[#5608D4]  font-bold text-base "
        >
          Assets managment
        </Link>
      </div>

      <div className="mx-auto lg:mx-12 bg-white min-h-screen">
        <div className="flex items-center justify-between gap-6 p-5">
          <div className="flex items-center gap-12">
            <Link
              to="aatools"
              className="font-Satoshi text-base text-[#000000] capitalize font-[500]"
            >
              Tools
            </Link>

            <div className="flex items-center gap-4 w-full lg:w-auto">
              <span className="text-[#121212] text-[12px] font-[400] font-Nunito hidden lg:flex">
                Search staff
              </span>
              <div className="flex items-center justify-between gap-2 border border-[#ACC3E7] px-3 py-2 rounded-lg w-full lg:w-auto">
                <input
                  type="text"
                  className="hidden lg:block   border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                />
                <input
                  type="text"
                  className="flex lg:hidden border-none focus:outline-none text-[#A3A3A3] placeholder-[#A3A3A3] focus:text-[#121212] w-full lg:w-auto"
                  placeholder="Search for a document"
                  value={searchQuery}
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                />
                <BsSearch className="text-[#000000]" />
              </div>
            </div>
          </div>
          <div>
            <button className="bg-[#000000] w-[113px] p-3 rounded-lg font-Satoshi text-[#FFFFFF] font-[14px]">
              Assign
            </button>
          </div>
        </div>

        <div className="p-4">
          <table className="w-full border-gray-300 bg-white overflow-x-auto overflow-y-scroll">
            <thead>
              <tr className="bg-[#F9FAFB] text-[#667085] text-sm font-Nunito font-[500] text-left">
                <th className="p-2">
                  <input type="checkbox" className="border border-[#B6C2E2]" />
                </th>
                <th className="p-2">Name</th>
                <th className="p-2">Asset Name</th>
                <th className="p-2">Asset ID</th>
                <th className="p-2">Details</th>
                <th className="p-2">Description</th>
              </tr>
            </thead>
            <tbody>
              {paginatedTools.map((tool) => (
                <tr key={tool.id} className="border-b font-Satoshi text-[11px]">
                  <td className="p-2">
                    <input
                      type="checkbox"
                      className="border border-[#B6C2E2]"
                    />
                  </td>
                  <td className="p-2 flex items-center gap-2">
                    <div className="w-8 h-8 text-[#000000] font-[700] bg-[#F4F6F6] object-contain object-center border border-[#5608D4] rounded-full flex justify-center items-center">
                      {tool.name}
                    </div>
                  </td>
                  <td>{tool.assetName}</td>
                  <td className="p-2">{tool.assetsId}</td>
                  <td className="p-2">{tool.detail}</td>
                  <td className="p-2">{tool.tools}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-center mt-4 space-x-2">
            {currentPage > 1 && (
              <button
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
                className={`px-4 py-2 rounded-md ${
                  currentPage === 1
                    ? "opacity-50 cursor-not-allowed bg-gray-300"
                    : "bg-black text-white hover:bg-gray-800"
                }`}
              >
                {"<<"}
              </button>
            )}

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-4 py-2 rounded-md ${
                  currentPage === index + 1
                    ? "bg-gradient-to-r from-purple-700 to-blue-700 text-white"
                    : "bg-black text-white hover:bg-gray-800"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-r-md"
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assign;
