/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// Import useAuthStore hook from the correct location
import useAuthStore from "./data/Stores/Authstore";
import useErrorStore from "./data/Stores/errorstore";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();

	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	// Retrieve page, id, and step from URL params
	const { page, id, step, four } = useParams();
	const escape2 = ["home", "about", "our-team", "contact-us", "sign-up"],
    escape = [
      "departments",
      "positions",
      "levels",
      "company",
      "grades",
      "media",
      "leave-types",
      "approval-flows",
      "permissions",
      "admins",
    ],
    escapeEmployee = [
      "add-new-employee",
      "offboarding",
      "onboarding",
      "tools-management",
    ],
    escapeRequest = ["id-requests"],
    escapePerformance = [
      "add-new-performance",
      "select-a-position",
      "add-performance",
      "performance-clone",
      "manage-evaluations-details",
    ],
    escapeOffboarding = ["tender-resignation"],
    escapeTools = ["assign-tools", "remove-tools", "add-tools"],
    escapeRecruit = ["new-job", "applications", "all-candidates"],
    escapeApplication = ["all-candidates"],
    escapePermi = ["information"],
    escapeFlow = ["leave-flow", "approval-flow-details"],
    escapeLeave = ["my-requests", "manage-request"],
    escapeResources = [
      "listviewfiles",
      "alldocument",
      "worddocument",
      "assetmanagement",
      "assign",
      "aatools",
      "removetool",
    ],
    navigate = useNavigate();

	// Use the useAuthStore hook to access authentication-related state
	const { isAuth } = useAuthStore(),
		{ errors, clearErrors } = useErrorStore();

	useEffect(() => {
		// Redirect to the homepage if the user is not authenticated
		if (!isAuth) {
			if (errors?.errorText) {
				if (page !== "login" && page !== "register") {
					navigate("/");
				}
				clearErrors();
			}
		}
		// Redirect to the homepage if the user is authenticated and tries to access login or register pages
		if (isAuth) {
			if (page === "login" || page === "register") {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, isAuth, navigate, errors?.errorText]);

	// Construct the page name based on URL params and available escape routes
	let pageName = "";
	if (four) {
    if (
      (page === "resources" && escapeResources.includes(four))
    ) {
      pageName = `${page}/${id}/${step}/${four}`;
    } else pageName = `${page}/${id}/${step}/${"[id]"}`;
	} else if (step) {
		if (
      (page === "manage-employees" &&
        id === "offboarding" &&
        escapeOffboarding?.includes(step)) ||
      (page === "manage-employees" &&
        id === "tools-management" &&
        escapeTools?.includes(step)) ||
      (page === "recruitment" &&
        id === "applications" &&
        escapeApplication?.includes(step)) ||
      (page === "company-settings" &&
        id === "approval-flows" &&
        escapeFlow?.includes(step)) ||
      (page === "company-settings" &&
        id === "permissions" &&
        escapePermi?.includes(step)) ||
      (page === "resources" && escapeResources.includes(step))
    ) {
      pageName = `${page}/${id}/${step}`;
    } else pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
      (page === "home" && escape2.includes(id)) ||
      (page === "company-settings" && escape.includes(id)) ||
      (page === "leave" && escapeLeave.includes(id)) ||
      (page === "requests" && escapeRequest.includes(id)) ||
      (page === "recruitment" && escapeRecruit.includes(id)) ||
      (page === "manage-employees" && escapeEmployee.includes(id)) ||
      (page === "performance" && escapePerformance.includes(id)) ||
      (page === "dashboard" && escape2.includes(id)) ||
        (page === "resources" && escapeResources.includes(id))
    ) {
      pageName = `${page}/${id}`;
    } else {
      pageName = `${page}/${"[id]"}`;
    }
	} else {
		pageName = `${page}`;
	}

	// Call generatePage with the constructed pageName and determine the folder based on user authentication status
	return GeneratePage(pageName, isAuth ? "pages" : "screens");
};

export default PageRender;
