import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { FiUpload } from "react-icons/fi";

import FolderImg from "../../assets/folderDetails.png";

export default function FolderDetail({ onClose }) {
  const [isFolderDetail, setIsFolderDetail] = useState(true);

  const handleFolderDetail = () => {
    setIsFolderDetail(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={isFolderDetail}
        as="div"
        className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center"
        onClose={onClose}
      >
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center p-4">
          <DialogPanel className="w-full max-w-[465px] rounded-xl bg-white p-6 shadow-lg">
            <div className="flex justify-between items-center">
              <DialogTitle
                as="h3"
                className="text-[20px] font-Kumbh font-[600] text-gray-800"
              >
                Folder Name
              </DialogTitle>
              <p
                className="float-right text-3xl cursor-pointer "
                onClick={onClose}
              >
                &times;
              </p>
            </div>
            <div className="mt-6 flex justify-between items-start gap-2">
              <div>
                <img src={FolderImg} alt="my folder" />
                <a
                  href="/example.pdf"
                  download="example.pdf"
                  className="py-2 text-[#5608D4] rounded-lg underline font-Nunito text-[14px] flex items-center gap-2"
                >
                  <span>
                    <FiUpload />
                  </span>
                  Download
                </a>
              </div>
              <div className="w-[291px]">
                <h3 className="text-[15px] font-Satoshi font-[500]">
                  Getting Started with HR Core Software
                </h3>
                <div className="flex items-center gap-3 mt-2">
                  <h4 className="bg-[#FCF3FF] text-[#7A12B9] py-2 px-4 rounded-full font-inter text-[10px]">
                    IT Department
                  </h4>
                  <h4 className="bg-[#F4FFF3] text-[#12B919] py-2 px-4 rounded-full font-inter text-[10px]">
                    Team Lead
                  </h4>
                </div>
                <p className="text-[12px] mt-4">
                  Lorem ipsum dolor sit amet consectetur. Mattis vitae accumsan
                  diam at metus imperdiet dictum. Vestibulum nunc massa est
                  senectus nunc. Faucibus eu tincidunt ut imperdiet eu ut
                  commodo vel. Vitae orci scelerisque nibh quam pellentesque
                  metus. Tempor ut mauris id rhoncus id. Quam proin augue
                  viverra ut. Purus leo.
                </p>
              </div>
            </div>

            <div className="text-[#475467] text-[14px] font-Nunito mt-5">
              <p>Size: 400mb</p>
              <p>Files: 5</p>
              <p>Collaborator: 10member</p>
              <p>Created on: 23/10/2024, 10:00:09pm</p>
              <p>Created by: Debs Jacobs</p>
              <p>Last modified: 23/10/2024, 10:00:09pm</p>
            </div>

            <div className="mt-16 flex justify-center items-center">
              <button
                className="rounded-md bg-[#5608D4] py-2 px-4 text-sm font-semibold text-white focus:ring focus:ring-purple-300 w-[143px] p-4 text-center font-Nunito"
                onClick={handleFolderDetail}
              >
                Okay
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
