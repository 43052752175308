import React from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ActionsFile from "../resources/ActionFile";

export default function FileCard({
  id,
  title,
  folderImg,
  name,
  date,
  toggleModal,
  isOpen,
}) {
  return (
    <div className="relative w-full md:w-full lg:w-[204px] lg:h-[127px] bg-white shadow-md px-3 py-2 rounded cursor-pointer">
      <div className="flex justify-between items-center">
        <img src={folderImg} alt={title} className="w-8 h-8" />
        <HiOutlineDotsVertical
          className="cursor-pointer text-[#667085]"
          onClick={(event) => toggleModal(id, event)}
        />
      </div>
      <div className="text-left mt-2 text-sm font-Nunito font-[400] text-[#101828]">
        {title}
      </div>
      <div className="flex items-center justify-between mt-2">
        <div className="text-[10px] font-semibold font-Nunito text-[#667085]">
          {name}
        </div>
        <div className="text-[10px] text-[#667085] font-Nunito">{date}</div>
      </div>

      {isOpen && <ActionsFile onClose={() => toggleModal(id)} />}
    </div>
  );
}
