import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
import WebViewer from "@pdftron/webviewer";

export const MainWordDocument = () => {
  const viewerRef = useRef(null);
  const [documentUrl, setDocumentUrl] = useState("");
  const [isOfficeEditorReady, setIsOfficeEditorReady] = useState(false);

  // Suppress ResizeObserver warnings
  useEffect(() => {
    const patchResizeObserver = () => {
      const originalError = console.error;
      console.error = (...args) => {
        if (args[0]?.includes("ResizeObserver loop completed")) {
          return;
        }
        originalError(...args);
      };
    };
    patchResizeObserver();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      console.log("Resize detected");
    });

    const element = viewerRef.current;
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib/",
        licenseKey: "your-license-key",
        initialDoc: "",
        enableOfficeEditing: true,
      },
      viewerRef.current
    )
      .then((instance) => {
        const { Core, UI } = instance;

        Core.documentViewer.addEventListener("documentLoaded", () => {
          const officeEditor = Core.getOfficeEditor?.();
          if (!officeEditor) {
            console.error("Office editor not initialized.");
          } else {
            setIsOfficeEditorReady(true);
            console.log("Office editor ready.");
          }
        });

        // UI.setToolbarGroup("toolbarGroup-Annotate");
        // UI.enableFeatures([UI.Feature.TextHighlight, UI.Feature.TextUnderline]);
        // UI.enableFeatures([
        //   UI.Feature.TextStrikeThrough,
        //   UI.Feature.TextRedac,
        // ]);
        // UI.enableFeatures([
        //   UI.Feature.TextInsertField,
        //   UI.Feature.TextInsertFieldCode,
        // ]);
        // UI.enableFeatures([
        //   UI.Feature.TextInsertField,
        //   UI.Feature.TextInsertFieldCode,
        // ]);
        // UI.enableFeatures([
        //   UI.Feature.TextInsertField,
        //   UI.Feature.TextInsertFieldCode,
        // ]);

        // UI.enableElements([
        //   "richTextPopup",
        //   "richTextUnderlineButton",
        //   "richTextItalicButton",
        //   "richTextColorPalette",
        // ]);
      })
      .catch((err) => console.error("WebViewer error:", err));
  }, []);

  const handleDocumentChange = (e) => setDocumentUrl(e.target.value);

  return (
    <div className="bg-[#F5F7F8]">
      <Navbar />
      <PagesList />
      <div className="flex items-center gap-4 px-6 lg:px-12 pt-4">
        <p className="font-Kumbh text-[#000000] text-base font-bold">
          Document Viewer
        </p>
      </div>
      <div className="mx-auto lg:mx-12 bg-white mt-5">
        <div
          className="h-[100vh] w-full bg-white mt-6 px-6 lg:px-12"
          ref={viewerRef}
        />
      </div>
    </div>
  );
};

const WordDocument = () => <MainWordDocument />;
export default WordDocument;
