import React, { useState } from "react";
import FileCard from "../cards/FileCard";

const BoardViewPage = ({ folders, toggleModal, openModalId }) => {
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [searchTerm, setSearchTerm] = useState("");

  // const handleToggleModal = (id, event = null) => {
  //   if (event && typeof event.stopPropagation === "function") {
  //     event.stopPropagation();
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     setModalPosition({ top: rect.top + window.scrollY, left: rect.left });
  //   }
  //   toggleModal(id);
  // };

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mb-6 p-4">
        {folders.map((file) => (
          <FileCard
            key={file.id}
            id={file.id}
            title={file.title}
            folderImg={file.folderImg}
            name={file.name}
            date={file.date}
            toggleModal={(event) => toggleModal(file.id, event)}
            isOpen={openModalId === file.id}
          />
        ))}
      </div>
    </div>
  );
};

export default BoardViewPage;
