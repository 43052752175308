import React, { useState } from "react";
import FileTable from "./FileTable";
import ActionsDocument from "./ActionDocument";
// import ActionsFile from "./ActionFile";
import { BsThreeDotsVertical } from "react-icons/bs";

const AllDocListView = ({ folders, openModalId, toggleModal}) => {
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [searchTerm, setSearchTerm] = useState("");

  // const handleToggleModal = (id, event = null) => {
  //   if (event && typeof event.stopPropagation === "function") {
  //     event.stopPropagation();
  //     const rect = event.currentTarget.getBoundingClientRect();
  //     setModalPosition({ top: rect.top + window.scrollY, left: rect.left });
  //   }
  //   toggleModal(id);
  // };

  return (
    <div className="">
      <FileTable>
        {folders.map((item) => (
          <tr
            key={item.id}
            className="text-sm text-[#121212] border-b border-[#EBEEF2] hover:bg-[#F5F5F5] relative"
          >
            <td className="py-4 px-4 flex items-center gap-3">
              {/* <CiFolderOn size={20} className="text-[#5608D4]" /> */}
              <img src={item.folderImg} alt={item.name} />
              <span className="text-[11px] font-Satoshi font-[300]">
                {item.title}
              </span>
            </td>
            <td className="text-[14px] text-[#667085] font-Inter font-[400] py-4 px-4">
              {item.name}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.createdOn}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.lastModified}
            </td>
            <td className="text-[11px] text-[#667085] font-Satoshi font-[300] py-4 px-4">
              {item.modifiedBy}
            </td>
            <td className="py-4 px-4 text-left relative">
              {/* Action Icon */}
              <BsThreeDotsVertical
                className="text-[#5608D4] cursor-pointer"
                onClick={() => toggleModal(`file-${item.id}`)}
              />
              {/* Modal for Actions */}
              {openModalId === `file-${item.id}` && (
                <div className="absolute right-0 top-8 z-10">
                  <ActionsDocument onClose={() => toggleModal(null)} />
                </div>
              )}
            </td>
          </tr>
        ))}
      </FileTable>
    </div>
  );
};

export default AllDocListView;
