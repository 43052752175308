import React, { useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoSearchOutline } from "react-icons/io5";
import OptionModal from "./OptionModal";
import ActionCompleted from "../cards/ActionCompleted";

const ShareFile = ({ onClose }) => {
  const [selectOption, setSelectOption] = useState("");
  const [addMember, setAddMember] = useState("");
  const [searchMember, setSearchMember] = useState("");
  const [isShareFileModalOpen, setIsShareFileModalOpen] = useState(true);
  const [isActionCompletedOpen, setIsActionCompletedOpen] = useState(false);

  const handleSend = () => {
    setIsShareFileModalOpen(false);
    setIsActionCompletedOpen(true);
  };

  const handleActionCompletedClose = () => {
    setIsActionCompletedOpen(false);
    onClose();
  };

  const handleOptionSelect = (option) => {
    setSelectOption(option);
  };

  const closeModal = () => {
    setSelectOption("");
  };

  const renderOptionModal = () => {
    switch (selectOption) {
      case "department":
        return (
          <OptionModal title="Department" onClose={closeModal}>
            <div className="flex items-center gap-3 my-2 w-[346px] focus:border-[#5608D4] py-1 px-3 rounded-lg  focus:ring-1 focus:ring-[#5608D4] border border-[#E0E0E0]">
              <input
                type="text"
                id="add-member"
                placeholder="Search"
                className=" text-[13px] text-[#101828] font-Nunito p-2 w-full focus:outline-none"
                value={searchMember}
                onChange={(ev) => setSearchMember(ev.target.value)}
              />
              <IoSearchOutline />
            </div>
            {[
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 1,
              },
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 2,
              },
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 3,
              },
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 4,
              },
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 5,
              },
              {
                department: "Accounting & Finance Department",
                member: "30 members",
                id: 6,
              },
            ].map((item) => (
              <div
                key={item.id}
                className="flex items-center gap-3 p-3 bg-[#FFFFFF] rounded-lg w-[361px] shadow-md"
              >
                <div>
                  <input type="checkbox" />
                </div>
                <div>
                  <h4 className="text-[#000000] text-[16px] font-medium font-satoshi">
                    {item.department}
                  </h4>
                  <p className="text-[#000] text-[13px] font-Nunito font-[300]">
                    {item.member}
                  </p>
                </div>
              </div>
            ))}
          </OptionModal>
        );
      case "level":
        return (
          <OptionModal title="Level" onClose={closeModal}>
            {[
              {
                level: "Intern",
              },
              {
                level: "Entry-level",
              },
              {
                level: "Entry-level",
              },
              {
                level: "Entry-level",
              },
              {
                level: "Entry-level",
              },
              {
                level: "Entry-level",
              },
            ].map((item) => (
              <div
                key={item.id}
                className="flex items-center gap-3 p-3 bg-[#FFFFFF] rounded-lg w-[361px] shadow-md my-2"
              >
                <div>
                  <input type="checkbox" />
                </div>
                <div>
                  <h4 className="text-[#000000] text-[16px] font-medium font-satoshi">
                    {item.level}
                  </h4>
                </div>
              </div>
            ))}
          </OptionModal>
        );
      case "grade":
        return (
          <OptionModal title="Grade" onClose={closeModal}>
            {[
              {
                grade: "Grade name",
              },
              {
                grade: "Grade name",
              },
              {
                grade: "Grade name",
              },
              {
                grade: "Grade name",
              },
              {
                grade: "Grade name",
              },
              {
                grade: "Grade name",
              },
            ].map((item) => (
              <div
                key={item.id}
                className="flex items-center gap-3 p-3 bg-[#FFFFFF] rounded-lg w-[361px] shadow-md my-2"
              >
                <div>
                  <input type="checkbox" />
                </div>
                <div>
                  <h4 className="text-[#000000] text-[16px] font-medium font-satoshi">
                    {item.grade}
                  </h4>
                </div>
              </div>
            ))}
          </OptionModal>
        );
      case "position":
        return (
          <OptionModal title="Position" onClose={closeModal}>
            {[
              {
                position: "Position name",
              },
              {
                position: "Position name",
              },
              {
                position: "Position name",
              },
              {
                position: "Position name",
              },
              {
                position: "Position name",
              },
              {
                position: "Position name",
              },
            ].map((item) => (
              <div
                key={item.id}
                className="flex items-center gap-3 p-3 bg-[#FFFFFF] rounded-lg w-[361px] shadow-md my-2"
              >
                <div>
                  <input type="checkbox" />
                </div>
                <div>
                  <h4 className="text-[#000000] text-[16px] font-medium font-satoshi">
                    {item.position}
                  </h4>
                </div>
              </div>
            ))}
          </OptionModal>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog
        as="div"
        className="relative z-50"
        open={isShareFileModalOpen}
        onClose={onClose}
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-30"
          aria-hidden="true"
        />
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          <DialogPanel className="w-full max-w-[600px] rounded-xl bg-white p-6 shadow-lg">
            <p
              className="float-right text-3xl cursor-pointer "
              onClick={onClose}
            >
              &times;
            </p>
            <div>
              <DialogTitle className="text-lg text-[20px] font-medium font-inter text-[#1D2939] mt-6">
                Share ‘Document Name’
              </DialogTitle>
              <p className="mt-2 text-sm text-[#475467]">Add members to file</p>
            </div>

            <div className="flex flex-col gap-3 my-8">
              <label
                htmlFor="add-member"
                className="text-[#344054] text-[14px] font-Nunito font-[500]"
              >
                Add members to document
              </label>
              <input
                type="text"
                id="add-member"
                placeholder="Data SurveyEnter email address(s), press Enter to save"
                className="w-full border border-[#E0E0E0] text-[13px] text-[#101828] font-Nunito p-2 rounded-lg focus:outline-none focus:border-[#5608D4] focus:ring-1 focus:ring-[#5608D4]"
                value={addMember}
                onChange={(ev) => setAddMember(ev.target.value)}
              />
            </div>

            <div className="mt-8">
              <p className="text-[13px] mb-3 font-[500]">Share by:</p>
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="options"
                  onClick={() => handleOptionSelect("department")}
                />
                <span className="text-[12px]">Department</span>
              </label>
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="options"
                  onClick={() => handleOptionSelect("level")}
                />
                <span className="text-[12px]">Level</span>
              </label>
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="options"
                  onClick={() => handleOptionSelect("grade")}
                />
                <span className="text-[12px]">Grade</span>
              </label>
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="radio"
                  name="options"
                  onClick={() => handleOptionSelect("position")}
                />
                <span className="text-[12px]">Position</span>
              </label>
            </div>

            <div className="mt-4 flex justify-center items-center gap-2">
              <button
                className="bg-[#5608D4] font-Nunito font-[400] text-[14px] w-[264px] text-white py-2 px-2 rounded-lg"
                onClick={handleSend}
              >
                Send Invite
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {renderOptionModal()}

      <ActionCompleted
        isOpen={isActionCompletedOpen}
        onClose={handleActionCompletedClose}
      />
    </>
  );
};


export default ShareFile;
