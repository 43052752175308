import React from "react";
import Navbar from "../../components/navbar/Navbar";
import PagesList from "../../components/pages-list/PagesList";
// import emojione from "../../assets/emoji-one.png";
// import emojitwo from "../../assets/emoji-two.png";
import empimg from "../../assets/empimg.png";
import empone from "../../assets/empone.png";
import emptwo from "../../assets/emptwo.png";
import empthree from "../../assets/empthree.png";
import empfour from "../../assets/empfour.png";
import { ImageChange } from "../dashboard";
import useMediaStore from "../../data/Stores/MediaStore";
import useAnnoucementStore from "../../data/Stores/AnnoucementStore";
import MyRequests from "../../components/tables/MyRequests";
import EmployeeMessage from "../../components/tables/EmployeeMessage";
import useAuthStore from "../../data/Stores/Authstore";

const EmployeeDashboard = () => {
  const media = useMediaStore(),
    announcement = useAnnoucementStore(),
    { user } = useAuthStore();

  const employees = [
    {
      img: empone,
      text: "All Tasks",
      num: "20",
      width: "50px",
      border: "1px solid #7e09a7",
      color: "#7e09a7",
    },
    {
      img: emptwo,
      text: "Pending Tasks",
      num: "05",
      width: "50px",
      border: "1px solid #117708",
      color: "#7e09a7",
    },
    {
      img: empthree,
      text: "Announcements",
      num: announcement?.data?.totalDocs || 0,
      width: "50px",
      border: "1px solid #0b0877",
      color: "#7e09a7",
    },
    {
      img: empfour,
      text: "My Requests",
      num: "12",
      width: "50px",
      border: "1px solid #d00e77",
      color: "#7e09a7",
    },
  ];
  return (
    <div className="min-h-screen bg-[#F4F6F8]">
      <Navbar />
      <PagesList />
      <div className=" mx-12">
        <div className="  pt-5 flex items-center gap-2 ">
          {/* <img className=" w-6 h-6" src={emojione} alt="" /> */}
          <p className="text-[#000] font-Kumbh font-[700]">
            Welcome, {user?.lastName}!
          </p>
          {/* <img className=" w-10 h-8" src=                                                                                                                                        \{emojitwo} alt="" /> */}
        </div>
      </div>

      <div className=" mx-12 flex items-center gap-5 mt-8">
        <div>
          <div className=" bg-white  flex items-start gap-4 p-4 w-[250px] h-[137px]">
            <img className=" w-12 h-12" src={user?.avatar || empimg} alt="" />
            <div>
              <p className=" font-[700] font-Kumbh text-[#000] text-base">
                {user?.lastName} {user?.firstName}
              </p>
              <p className="font-Satoshi font-[400]  text-sm text-[#000] mt-1">
                {user?.department?.name}
              </p>
              <p className="font-Satoshi font-[400] text-sm text-[#000] mt-1">
                {user?.position?.name}
              </p>
              <p className=" text-[#6b15be] text-sm font-bold mt-4 font-Satoshi">
                {user?.level?.name}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className=" bg-[#cccccc] h-24 w-[0.7px]"></div>
        </div>
        <div className=" grid grid-cols-4 items-center gap-5 ">
          {employees.map((i) => (
            <div className=" bg-white w-[249px] h-[130.5px] rounded-lg flex gap-10 justify-between items-center px-4 py-4">
              <div className=" grid gap-2">
                <div className=" h-[50px]">
                  <img
                    style={{ width: i.width, height: i.height }}
                    src={i.img}
                    alt=""
                  />
                </div>
                <p className=" text-sm text-[#000] Satoshi font-medium">
                  {i.text}
                </p>
              </div>
              <div
                style={{ border: i.border }}
                className="w-10 h-10 rounded-2xl grid justify-center items-center"
              >
                <p className=" text-[#071c50] font-bold">{i.num}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <div className=" mx-12 flex justify-center items-center pt-10">
        <div className=" w-[45%] h-80">
          <ImageChange item={media?.data?.docs} />
        </div>
        <div className=" w-[55%] rounded-md p-4">
          <InvoiceTable />
        </div>
      </div> */}

      <div className=" mx-12 pt-5 flex justify-between gap-5">
        <div className=" w-[45%]">
          <div className=" h-80">
            <ImageChange item={media?.data?.docs} />
          </div>
          <MyRequests />
        </div>
        <div className=" w-[55%]">
          <EmployeeMessage />
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
